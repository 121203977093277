import { Button, Form, Modal } from "react-bootstrap";

import { AuthContext } from "../../helpers/Contexts";
import { IUser } from "../../entities/User";
import React from "react";
import { postData } from "../../helpers/Data";

interface IProps {
  user: IUser;
  show: boolean;
  onClose: any;
}

interface IState {
  user: IUser;
  show: boolean;
}

export default class UserProfileDialog extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: this.props.user,
      show: false,
    };
  }
  static contextType = AuthContext;
  componentDidMount = () => {};

  setSelectedUserField = (value: any, field: keyof IUser) => {
    let user = this.state.user;
    user = this.prop(user, field, value);
    this.setState({ user: user });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  onSave = () => {
    let user = this.state.user;
    user.companyId = this.context.selectedCompany.id;
    postData("users", user).then((res) => {
      this.props.onClose();
    });
  };

  render() {
    console.log("User Profile", this.state);
    return (
      <div>
        <Modal show={this.props.show} onHide={() => this.props.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.user.id ? "Edit " + this.state.user.fullName : "Create User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  disabled
                  value={this.state.user.emailAddress}
                  type="email"
                  placeholder="Enter Email Address"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "emailAddress")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.user.firstName}
                  placeholder="Enter Name"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "firstName")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  value={this.state.user.surname}
                  placeholder="Enter Surname"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "surname")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  value={this.state.user.mobileNumber}
                  name="mobile"
                  placeholder="Enter Mobile Number"
                  onChange={(e) => this.setSelectedUserField(e.target.value, "mobileNumber")}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  value={this.state.user.password}
                  placeholder="Enter Password"
                  type=""
                  onChange={(e) => this.setSelectedUserField(e.target.value, "password")}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Leave password field blank if you don't wish to change it. Please note that the password needs to contain a uppercase letter, a lower case
                  letter, a number and a scpecial character ( !@$?_- )
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={this.state.user.twoFactorEnabled}
                  label="Requires Two Factor Login"
                  onChange={(e) => {
                    this.setSelectedUserField(e.target.checked, "twoFactorEnabled");
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
