import { Button, ButtonGroup, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { deleteData, getData, postData } from "../../helpers/Data";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICompany } from "../../entities/Company";
import { IMaintenanceTypeList } from "../../entities/MaintenanceTypes";
import React from "react";
import Select from "react-select";

interface IProps {}

interface IState {
  maintenanceTypes: IMaintenanceTypeList[];
  selectedCompany: ICompany;
  selectedMaintenanceType: IMaintenanceTypeList;
  showEditDialog: boolean;
  users: any;
}

export default class MaintenanceTypes extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedCompany: {
        active: true,
        allocateDocumentNumber: "",
        artisanHandoverFlag: false,
        companyPrefix: "",
        finalReviewTitle: "",
        jobCardPrefix: "",
        maintenance: false,
        name: "",
        plannedMaintenance: false,
        requiredMaintenanceWhy: false,
        requiredQualityWhy: false,
        toolsAndSparesFlag: false,
        tradingAsName: "",
        userDefinedFields: [],
      },
      maintenanceTypes: [],
      showEditDialog: false,
      selectedMaintenanceType: { isBreakdown: false, name: "", companyId: "" },
      users: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getCompany();
    this.refresh();
    this.refreshUsers(this.context.selectedCompany?.id);
  }

  refresh() {
    getData("MaintenanceTypes", { companyId: this.context.selectedCompany?.id }).then((data) => {
      console.log("maintenanceTypes", data);
      this.setState({ maintenanceTypes: data });
    });
  }

  getCompany = () => {
    getData("companies/" + this.context.selectedCompany.id).then((data) => {
      this.setState({ selectedCompany: data });
    });
  };

  openCreateDialog = () => {
    this.setState({ selectedMaintenanceType: { isBreakdown: false, name: "", companyId: "" }, showEditDialog: true });
  };

  openEditDialog = (matintenanceType: IMaintenanceTypeList) => {
    this.refreshUsers(matintenanceType.companyId);
    this.setState({ selectedMaintenanceType: { ...matintenanceType }, showEditDialog: true });
  };

  onClose = () => {
    this.setState({ showEditDialog: false, selectedMaintenanceType: { isBreakdown: false, companyId: "", name: "" } });
  };

  onSave = () => {
    let mainType = this.state.selectedMaintenanceType;
    mainType.companyId = this.context.selectedCompany.id;
    postData("maintenanceTypes", mainType).then((date) => {
      this.setState({ showEditDialog: false, selectedMaintenanceType: { isBreakdown: false, name: "", companyId: "" } }, () => this.refresh());
    });
  };

  refreshUsers = (id: string) => {
    getData("users/GetUserDDL", { companyId: id }).then((data) => {
      //   console.log("Users", data);
      this.setState({ users: data });
    });
  };

  getUser = (id: string) => {
    let user = "";

    this.state.users.forEach((option: any) => {
      if (option.value === id) {
        user = option.label;
      }
    });

    return user;
  };

  setSelectedMaintenanceTypeField = (value: any, field: keyof IMaintenanceTypeList) => {
    let mainType = this.state.selectedMaintenanceType;
    mainType = this.prop(mainType, field, value);
    this.setState({ selectedMaintenanceType: mainType });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  delete = (id: string | undefined) => {
    deleteData("maintenanceTypes/", { id: id }).then((data) => {
      this.refresh();
    });
  };

  render() {
    return (
      <div>
        <Card style={{ width: "100%" }}>
          <Card.Header>
            <Card.Title>
              Maintenance Priorities
              <span className="float-right">
                <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                  Add Maintenance Priority
                </Button>
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Breakdown</th>
                      <th>Manager</th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.maintenanceTypes.length > 0
                      ? this.state.maintenanceTypes.map((mainType: IMaintenanceTypeList) => {
                          return (
                            <tr>
                              <td>{mainType.name}</td>
                              <td>{mainType.isBreakdown ? "Yes" : "No"}</td>
                              <td>{mainType.maintenanceManager && this.state.users.length > 0 ? this.getUser(mainType.maintenanceManager) : null}</td>
                              <td>
                                <Button variant="primary" size="sm" onClick={() => this.openEditDialog(mainType)}>
                                  <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => this.delete(mainType.id)}>
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>{this.state.selectedMaintenanceType.id ? "Edit " + this.state.selectedMaintenanceType.name : "Create Maintenance Type"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.selectedMaintenanceType.name}
                  placeholder="Enter Maintenance Type Name"
                  onChange={(e) => this.setSelectedMaintenanceTypeField(e.target.value, "name")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Manager</Form.Label>
                <Select
                  options={this.state.users}
                  onChange={(inv: any) => {
                    this.setSelectedMaintenanceTypeField(inv.value, "maintenanceManager");
                  }}
                  value={
                    this.state.selectedMaintenanceType.maintenanceManager
                      ? this.state.users.filter((option: any) => option.value === this.state.selectedMaintenanceType.maintenanceManager)
                      : null
                  }
                  placeholder="Select Maintenance Manager"
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  checked={this.state.selectedMaintenanceType.isBreakdown}
                  label="Is Breakdown"
                  onChange={(e) => this.setSelectedMaintenanceTypeField(e.target.checked, "isBreakdown")}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
