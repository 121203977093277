import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { IAnnouncement, INotification } from "../../entities/Notification";
import { getData, postData } from "../../helpers/Data";

import { IJobCardNotification } from "../../entities/JobCardNotification";
import { IUserList } from "../../entities/User";
import React from "react";
import moment from "moment";

interface IProps {}

interface IState {
  announcement: IAnnouncement;
  announcements: INotification[];
  jobCardAnnouncements: IJobCardNotification[];
  showEditDialog: boolean;
  users: IUserList[];
}

export default class Announcement extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      announcement: {
        message: "",
        title: "",
        correctiveAction: false,
        maintenance: false,
      },
      announcements: [],
      jobCardAnnouncements: [],
      showEditDialog: false,
      users: [],
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    getData("users", {
      role: "Administrators",
    }).then((data) => {
      console.log("Users", data);
      this.setState({
        users: data.items,
      });
    });

    getData("notifications/GetAllAnnouncements").then((data) => {
      console.log("Announcements", data);
      this.setState({ announcements: data });
    });

    getData("jobCardNotifications/getAllJobCardAnnouncements").then((data) => {
      this.setState({ jobCardAnnouncements: data });
    });
  }

  onClose = () => {
    this.setState({
      showEditDialog: false,
      announcement: { message: "", title: "", correctiveAction: false, maintenance: false },
    });
  };

  openCreateDialog = () => {
    this.setState({
      showEditDialog: true,
      announcement: { message: "", title: "", correctiveAction: false, maintenance: false },
    });
  };

  onSave = () => {
    if (this.state.announcement.correctiveAction) {
      postData("notifications/announcement", this.state.announcement).then((res) => {
        this.setState({ showEditDialog: false, announcement: { message: "", title: "", correctiveAction: false, maintenance: false } }, () => this.refresh());
      });
    }
    if (this.state.announcement.maintenance) {
      postData("jobCardNotifications/jobCardAnnouncement", this.state.announcement).then((res) => {
        this.setState({ showEditDialog: false, announcement: { message: "", title: "", correctiveAction: false, maintenance: false } }, () => this.refresh());
      });
    }
  };

  setAnnouncementField = (value: any, field: keyof IAnnouncement) => {
    let announcement = this.state.announcement;
    announcement = this.prop(announcement, field, value);
    this.setState({ announcement: announcement });
  };

  prop<T, K extends keyof T>(obj: T, key: K, newVal: any) {
    obj[key] = newVal;
    return obj;
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  Announcements{" "}
                  <span className="float-right">
                    <Button variant="primary" className="" onClick={() => this.openCreateDialog()}>
                      Create Announcement
                    </Button>
                  </span>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <h5>Corrective Action Announcements</h5>
                </Row>
                <Row>
                  <Col>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Message</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.announcements.map((announcement: INotification) => {
                          return (
                            <tr>
                              <td>{announcement.title}</td>
                              <td>{announcement.message}</td>
                              <td>{moment(announcement.createdOn).format("DD MMM YYYY hh:mm")}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <Row>
                  <h5>Maintenance Announcements</h5>
                </Row>
                <Row>
                  <Col>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Message</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.jobCardAnnouncements.map((announcement: IJobCardNotification) => {
                          return (
                            <tr>
                              <td>{announcement.title}</td>
                              <td>{announcement.message}</td>
                              <td>{moment(announcement.createdOn).format("DD MMM YYYY hh:mm")}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={this.state.showEditDialog} onHide={() => this.onClose()} animation={false}>
          <Modal.Header>
            <Modal.Title>Create Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  value={this.state.announcement.title}
                  placeholder="Enter Title"
                  onChange={(e) => this.setAnnouncementField(e.target.value, "title")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  value={this.state.announcement.message}
                  as="textarea"
                  rows={4}
                  placeholder="Enter Your Annoucement"
                  onChange={(e) => this.setAnnouncementField(e.target.value, "message")}
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  checked={this.state.announcement.correctiveAction}
                  label="Corrective Action"
                  onChange={(e) => {
                    this.setAnnouncementField(e.target.checked, "correctiveAction");
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  checked={this.state.announcement.maintenance}
                  label="Maintenance"
                  onChange={(e) => {
                    this.setAnnouncementField(e.target.checked, "maintenance");
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.onClose()}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.onSave();
              }}
              disabled={!this.state.announcement.correctiveAction && !this.state.announcement.maintenance}
            >
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
