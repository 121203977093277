import "../../App.css";

import * as htmlToImage from "html-to-image";

import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { faCalendar, faDownload } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../helpers/Contexts";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DatePicker from "react-datepicker";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
import download from "downloadjs";
import { getData } from "../../helpers/Data";
import moment from "moment";

interface IProps {}

interface IState {
  filterItem: string;
  fromDate?: Date;
  statistics?: any;
  toDate?: Date;
}

const backgroundColor = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
];

export default class DefinedFieldsDashboard extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      filterItem: "2",
      fromDate: moment().startOf("year").toDate(),
      toDate: moment().endOf("day").toDate(),
    };
  }

  static contextType = AuthContext;
  componentDidMount = () => {
    this.refresh();
  };

  refresh() {
    getData("reports/userdefinedfields", {
      companyId: this.context.selectedCompany?.id,
      from: this.state.fromDate !== undefined ? moment(this.state.fromDate).format("DD MMM YYYY") : "",
      to: this.state.toDate !== undefined ? moment(this.state.toDate).format("DD MMM YYYY hh:mm") : "",
    }).then((data) => {
      console.log("DASH", data);
      this.setState({ statistics: data });
    });
  }

  setFilterItem = (items: any) => {
    let newFilterItem: string = "";

    items.forEach((element: any) => {
      newFilterItem = element.value;
    });

    let newFromDate = undefined;
    let newToDate = undefined;

    if (newFilterItem === "1") {
      newFromDate = moment().startOf("month").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "2") {
      newFromDate = moment().startOf("year").toDate();
      newToDate = moment().endOf("day").toDate();
    } else if (newFilterItem === "3") {
      newFromDate = moment().startOf("month").subtract(6, "months").toDate();
      newToDate = moment().endOf("day").toDate();
    }

    this.setState({ filterItem: newFilterItem, fromDate: newFromDate, toDate: newToDate }, () => this.refresh());
  };

  saveChartToPng = async (chartId: string, name: string) => {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    htmlToImage
      .toPng(canvas)
      .then((dataUrl) => {
        download(dataUrl, `${name}.png`);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  render() {
    if (this.state.statistics) {
      let companyPrefix = this.context.selectedCompany.companyPrefix != null ? this.context.selectedCompany.companyPrefix : "CAR";

      const selectOptions = [
        { value: "1", label: "Month to Date" },
        { value: "2", label: "Year to Date" },
        { value: "3", label: "Custom" },
      ];

      let row;
      if (Number(this.state.filterItem) < 3) {
        row = (
          <Row className="align-items-center">
            <Col>Quality Defined Fields Dashboard</Col>
            <Col sm={3} md={3} lg={3}>
              <Select options={selectOptions} defaultValue={selectOptions[1]} isMulti onChange={(e) => this.setFilterItem(e)} placeholder="All" />
            </Col>
          </Row>
        );
      } else if (this.state.filterItem === "3") {
        row = (
          <Row className="align-items-center">
            <Col>Quality Defined Fields Dashboard</Col>
            <Col sm={3} md={3} lg={3}>
              <Select options={selectOptions} isMulti onChange={(e) => this.setFilterItem(e)} />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.fromDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ fromDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="customDatePickerWidth">
                <DatePicker
                  customInput={
                    <InputGroup className="">
                      <Form.Control placeholder="Date" value={moment(this.state.toDate).format("DD/MM/YYYY")} />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  }
                  onChange={(date: Date) => this.setState({ toDate: date }, () => this.refresh())}
                />
              </div>
            </Col>
          </Row>
        );
      }

      return (
        <div>
          <Card style={{ width: "100%" }}>
            <Card.Header>
              <Card.Title>{row}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {this.state.statistics.carPercentagePerUserDefinedFields?.map((userDefinedField: any, i: number) => {
                  const userDefinedFieldsOptions = {
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      title: {
                        display: true,
                        text: companyPrefix + `% Per ${userDefinedField?.userDefinedFieldName}`,
                        font: {
                          size: 24,
                        },
                      },
                      datalabels: {
                        display: true,
                        align: "bottom",
                        borderRadius: 3,
                        font: {
                          size: 24,
                        },
                        color: "#fff",
                        formatter: (value: any) => {
                          return value + "%";
                        },
                      },
                    },
                  };
                  const userDefinedFieldsPercentageData = {
                    labels: userDefinedField?.userDefinedFields.map((b: any, i: number) => b.option),
                    datasets: [
                      {
                        label: "%",
                        data: userDefinedField?.userDefinedFields.map((b: any, i: number) => b.percentage),
                        backgroundColor: backgroundColor,
                        borderWidth: 0,
                      },
                    ],
                  };

                  return (
                    <Col lg={3} className="text-center">
                      <Button
                        variant={"success"}
                        size="sm"
                        title="Download Chart"
                        onClick={() => this.saveChartToPng(i.toString(), `${companyPrefix}% Per ${userDefinedField?.userDefinedFieldName}`)}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </Button>
                      <div id={i.toString()} style={{ backgroundColor: "white" }}>
                        <Doughnut data={userDefinedFieldsPercentageData} height={400} plugins={[ChartDataLabels]} options={userDefinedFieldsOptions} />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
